.gx-app-logo-content-bg:before {
    background-color: rgba(0, 73, 0, 0.64) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #057d43 !important;
    border-color: #057d43 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #057d43 !important;
}

.forget-password {

    .btn-submit {

        margin-top: 15px;

        .ant-btn-primary {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
            width: 100% !important;
            align-items: center;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
        }

    }

    .btn-back {

        .ant-btn-primary {
            background-color: #ffffff !important;
            border-color: #004926 !important;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #ffffff !important;
            border-color: #004926 !important;
        }

        .ant-btn-icon-only.ant-btn-lg {
            width: 50px;
            height: 50px;
        }

    }

    .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24, .ant-col-xl-24 {
        padding: 0px 0px !important;
    }

    .ant-form label {
        font-size: 12px !important;
        color: grey !important;
    }

    .ant-form-item {
        margin: 0px !important;
    }

    .ant-col-24{
        padding: 0px 0px 4px !important;
    }

}

.form-signin {

    .btn-submit-login {

        .ant-btn-primary {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
        }

    }

    .ant-form label {
        font-size: 12px !important;
        color: grey !important;
    }

    .ant-form-item {
        margin: 0px !important;
    }

    .ant-form-item-required::before {
        display: none !important;
    }

    .ant-col-24{
        padding: 0px 0px 4px !important;
    }

}

.form-signup {

    background-color: #004926 !important;
    width: 100vw;
    height: 100vh;
    padding: 100px;
    overflow-y: auto;

    .signup-logo-content {
        display: flex;
        background-color: #004926;
        width: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .btn-submit-login {

        .ant-btn-primary {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
            width: 100% !important;
        }

        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background-color: #057d43 !important;
            border-color: #057d43 !important;
        }

    }

    .gx-card {
        border-radius: 0px !important;
        margin-bottom: 0px !important;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
    }

    .ant-form label {
        font-size: 12px !important;
        color: grey !important;
    }

    .ant-form-item {
        margin: 0px !important;
    }

    .ant-form-item-required::before {
        display: none !important;
    }

    .ant-col-24{
        padding: 0px 0px 4px !important;
    }

    .ant-col-6{
        padding: 0px 0px !important;
    }

    .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24, .ant-col-xl-24 {
        padding: 0px 5px !important;
    }

    .ant-col-md-6, .ant-col-lg-6 {
        padding: 0px 0px !important;
    }

    .signup-form-content {
        .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24, .ant-col-xl-24 {
            padding: 0px 5px !important;
        }

        p {
            margin: 0px !important;
        }
    }

}


@media screen and (max-width: 600px) {

    .form-signup {

        background-color: #004926 !important;
        width: 100vw;
        height: 100vh;
        padding: 10px;
        overflow-y: auto;

        .btn-submit-login {

            .ant-btn-primary {
                background-color: #057d43 !important;
                border-color: #057d43 !important;
            }

            .ant-btn-primary:hover, .ant-btn-primary:focus {
                background-color: #057d43 !important;
                border-color: #057d43 !important;
            }

        }

        .gx-card {
            border-radius: 0px !important;
            margin-bottom: 0px !important;
            box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
        }

        .ant-form label {
            font-size: 12px !important;
            color: grey !important;
        }

        .ant-form-item {
            margin: 0px !important;
        }

        .ant-form-item-required::before {
            display: none !important;
        }

        .ant-col-24{
            padding: 0px 0px 4px !important;
        }

        .ant-col-6{
            padding: 0px 0px !important;
        }

        .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24, .ant-col-xl-24 {
            padding: 0px 0px !important;
        }

        .ant-col-md-6, .ant-col-lg-6 {
            padding: 0px 0px !important;
        }

        .signup-logo-content {
            display: flex;
            background-color: #004926;
            width: 100%;
            padding: 40px;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .signup-form-content {

            p {
                margin: 10px 0px !important;
            }
        }

    }

}

.table-summary{
  border-collapse: collapse;
  tfoot{
    tr{
      td{
        border-top:1px solid #e8e8e8;
        border-right:1px solid #e8e8e8;
        text-align: center;
        p{
          position: relative;
          top:10px;
        }
      }
      td:last-of-type {
        border-right: none !important;
      }
    }
  }
}
