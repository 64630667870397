.ant-btn {
  &:hover {
    color: #004925 !important;
    border-color: #004925 !important;
  }
}

.ant-btn-danger {
  &:hover {
    color: #fff !important;
    border-color: #ff7875 !important;
  }
}

.ant-btn-primary {
  &:hover {
    color: #fff !important;
    border-color: #015f31 !important;
    background-color: #015f31 !important;
  }

  &:active {
    color: #fff !important;
    background-color: #004925 !important;
    border-color: #004925 !important;
  }

  background-color: #004925 !important;
  border-color: #004925 !important;
}

a, gx-link {
  color: #004925 !important;
}

.ant-pagination-item-active {
  border-color: #004925 !important;
}

.ant-upload-select-picture-card {
  &:hover {
    border-color: #004925 !important;
  }

  &:active {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }

  &:focus {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }
}

.ant-input-number {
  &:hover {
    border-color: #004925 !important;
  }

  &:focus {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }

  &:active {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }
}

.ant-input {
  &:hover {
    border-color: #004925 !important;
  }

  &:focus {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }

  &:active {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }
}

.ant-select-selection {
  &:hover {
    border-color: #004925 !important;
  }

  &:active {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }

  &:focus {
    border-color: #004925 !important;
    box-shadow: 0 0 0 2px rgba(0, 73, 37, 0.2);
  }
}

.ant-menu-item {
  a {
    color: #fff !important;

    &:hover {
      color: #fa8c15 !important;
    }
  }
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}

.ant-switch-checked {
  background-color: #004925 !important;
}
